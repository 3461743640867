import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssignsComponent } from './components/assigns/assigns.component';
import { EditAssignComponent } from './components/assigns/edit-assign/edit-assign.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { DashboardsComponent } from './components/dashboards/dashboards.component';
import { InitComponent } from './components/init/init.component';
import { LoginComponent } from './components/login/login.component';
import { ReportComponent } from './components/report/report.component';
import { UsersComponent } from './components/users/users.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path:''                       , redirectTo: 'init', pathMatch:'full'},
  { path:'login'                  , component:LoginComponent },
  { path:'login/:token'           , component:LoginComponent },
  { path:'init'                   , component:InitComponent , canActivate:[AuthGuard] },
  { path:'company'                , component:CompaniesComponent , canActivate:[AuthGuard] },
  { path:'users'                  , component:UsersComponent , canActivate:[AuthGuard] },
  { path:'dashboards'             , component:DashboardsComponent , canActivate:[AuthGuard] },
  { path:'assigns'                , component:AssignsComponent,  canActivate:[AuthGuard] },
  { path:'assigns/:id'            , component:EditAssignComponent,  canActivate:[AuthGuard] },
  { path:'report'                 , component:ReportComponent,  canActivate:[AuthGuard] }

]



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
