<div
  [ngClass]   = "{'menu-expand': !collapse , 'menu-collapse' : collapse }"
  (mouseover) = "collapseMenu(false)"
  (mouseout)  = "collapseMenu(true)"
  class       = "d-flex flex-column">
  <a
    href                   ="/"
    class                  ="d-block p-3 link-dark text-decoration-none"
    data-bs-toggle         ="tooltip"
    data-bs-placement      ="right"
    data-bs-original-title ="Icon-only">

    <svg class="bi" width="40" height="32"><use xlink:href="#bootstrap"></use></svg>
    <img src="assets/images/soul_icon.svg">
  </a>
  <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
    <li  *ngFor="let item of items">
      <a [routerLink]="item.link" [ngClass]="{'active': this.router.url === item.link }" class="active">
        <mat-icon> {{item.icon}} </mat-icon>
        <span class="ml-1">{{item.text}}</span>
      </a>
    </li>

  </ul>

  <ul class="my-5 nav nav-pills nav-flush flex-column mb-auto text-center">
    <li>
        <mat-icon matSuffix >cancel</mat-icon>
        <span class="ml-1" (click)="logout()" >Cerrar sesión</span>
    </li>
  </ul>
</div>
