<div class="row mb-4">
  <div class="col-12">
    <img routerLink="/assigns" src="assets/images/return.png" alt="">
  </div>
</div>

<div>
  <div class="row">
    <div class="col-12">
      <h1 class="mb-1">Asignar dashboard</h1>
      <span class="text-muted id_number" *ngIf="assign"> {{assign.dashboard.name}} </span>
      <hr class="mt-0 mb-4">
    </div>
  </div>

  <form action="" [formGroup]="formAssign" >
    <div class="row mb-3">
      <div class="col-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Dashboard</mat-label>
          <input
              matInput
              formControlName   ="dashboard"
              type              ="text"
              placeholder       ="Seleccione un dashboard"
              [matAutocomplete] ="auto"
              >
          <mat-autocomplete
            [displayWith]="displayDash.bind(this)"
            autoActiveFirstOption #auto="matAutocomplete"
            >
            <mat-option *ngFor="let dash of filteredDashes | async" [value]="dash.id">
              {{dash.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error>Campo obligatorio</mat-error>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Tipo</mat-label>
          <mat-select
            formControlName = "type_selection"
            (selectionChange)="getCustomers(type_selection.value)"
            #type_selection
            >
            <mat-option value="1">Por usuario</mat-option>
            <mat-option value="2">Por empresa</mat-option>
          </mat-select>
          <mat-error>Campo obligatorio</mat-error>
        </mat-form-field>
      </div>


      <div class="col-4">
        <mat-form-field
          class      = "example-full-width"
          appearance = "fill"
          *ngIf      = "type_selection.value == '1'"
          >
          <mat-label>Cliente</mat-label>
          <input
              matInput
              type              ="text"
              placeholder       = "Seleccione un cliente"
              formControlName   ="customer"
              [matAutocomplete] ="auto"
              >
          <mat-autocomplete
            autoActiveFirstOption
            #auto             = "matAutocomplete"
            [displayWith]     = "displayCustomer.bind(this)"
            (optionSelected)  = "getCollabs($event.option.value)"
            >
            <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.id">
              {{customer.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

    </div>

    <div class="row">
      <div class="col-md-5 col-sm-12">
        <div *ngIf = "type_selection.value == '1'">
          <div class="d-flex justify-content-between bd-highlight">
            <h4>Disponibles
                <span
                  *ngIf="collabsUnSel"
                  class="asignados">({{collabsUnSel.length}})</span>
            </h4>
            <mat-checkbox
                #selectAllUnsel
                class    = "example-margin"
                (change) = "selectAllUnsel.checked ? collabsUnselEl.selectAll() : collabsUnselEl.deselectAll()"
              >Todos</mat-checkbox>
          </div>

          <mat-selection-list
            #collabsUnselEl
            formControlName ="collabsUnsel"
            class           ="border-rec"
            >
            <mat-list-option [value]="collab.id" *ngFor="let collab of collabsUnSel">
              {{ collab.first_name }} {{ collab.last_name }}
              <span class="text-muted id_number">Id: {{ collab.id_number }}</span>
            </mat-list-option>
          </mat-selection-list>
        </div>


        <div *ngIf = "type_selection.value == '2'">
          <div class="d-flex justify-content-between bd-highlight">
            <h4>Disponibles
                <span
                  *ngIf="customersUnSel"
                  class="asignados">({{customersUnSel.length}})</span>
            </h4>
            <mat-checkbox
                #selectAllUnsel
                class    = "example-margin"
                (change) = "selectAllUnsel.checked ? customersUnselEl.selectAll() : customersUnselEl.deselectAll()"
              >Todos</mat-checkbox>
          </div>

          <mat-selection-list
            #customersUnselEl
            formControlName ="customersUnsel"
            class           ="border-rec"
            >
            <mat-list-option [value]="customer.id" *ngFor="let customer of customersUnSel">
              {{ customer.name }}
            </mat-list-option>
          </mat-selection-list>
        </div>
      </div>


      <div class="col-2">
        <div class="d-flex justify-content-center mt-5">
          <button
            mat-raised-button
            color = "success"
            (click) ="type_selection.value == 1 ? passUsers('right') : passCustomer('right')"
            >
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <button
            mat-raised-button
            (click) = "type_selection.value == 1 ? passUsers('left') : passCustomer('left')"
            color   = "success">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>

      </div>
      <div class="col-md-5 col-sm-12">
        <div *ngIf = "type_selection.value == '1'">
          <div class="d-flex justify-content-between bd-highlight">
            <h4>Asignados
              <span
                *ngIf="collabsSel"
                class="asignados">({{collabsSel.length}})</span> </h4>
            <mat-checkbox
              #selectAllsel
              class    ="example-margin"
              (change) = "selectAllsel.checked ? collabsselEl.selectAll() : collabsselEl.deselectAll()"
              >Todos
            </mat-checkbox>
          </div>

          <mat-selection-list
            #collabsselEl
            formControlName = "collabsSel"
            class           = "border-rec"
            >
            <mat-list-option [value]="collab.id" *ngFor="let collab of collabsSel">
              {{ collab.first_name }} {{ collab.last_name }}
              <span class="text-muted id_number">Id: {{ collab.id_number }}</span>
            </mat-list-option>
          </mat-selection-list>
        </div>

        <div *ngIf = "type_selection.value == '2'">
          <div class="d-flex justify-content-between bd-highlight">
            <h4>Asignados
              <span
                *ngIf="customersSel"
                class="asignados">({{customersSel.length}})</span> </h4>
          <mat-checkbox
            #selectAllsel
            class    ="example-margin"
            (change) = "selectAllsel.checked ? customersselEl.selectAll() : customersselEl.deselectAll()"
            >Todos</mat-checkbox>
          </div>

          <mat-selection-list
            #customersselEl
            formControlName = "customersSel"
            class           = "border-rec"
            >
            <mat-list-option [value]="customer.id" *ngFor="let customer of customersSel">
              {{ customer.name }}
            </mat-list-option>
          </mat-selection-list>
        </div>

      </div>
    </div>


    <div class="row mt-4">
      <div class="col-12">
        <h1 class="mb-1">Asignar filtros</h1>
        <hr class="mt-0">
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <table  class="table example-form " >
          <tbody>
            <tr  formArrayName="filters" *ngFor="let item of getFiltersControls(); let i = index;">
              <ng-container [formGroupName]="i" >
                <td>
                  <input type="hidden" formControlName="id" >
                  <mat-form-field appearance="fill"  >
                    <mat-label>Tabla</mat-label>
                    <input
                      matInput
                      type              = "text"
                      formControlName   = "table"
                      autocomplete      = "off"
                      placeholder       = "Nombre de la columna">
                      <mat-error> Campo obligatorio </mat-error>
                  </mat-form-field>

                </td>
                <td>
                  <mat-form-field appearance="fill"  >
                    <mat-label>Columna</mat-label>
                    <input
                      matInput
                      type              = "text"
                      formControlName   = "column"
                      autocomplete      = "off"
                      placeholder       = "Nombre de la columna">
                      <mat-error> Campo obligatorio </mat-error>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="fill"  >
                    <mat-label>Valores</mat-label>
                    <input
                      matInput
                      type              = "text"
                      formControlName   = "values"
                      autocomplete      = "off"
                      placeholder       = "Separados por una coma(,)">
                      <mat-error> Campo obligatorio </mat-error>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="fill"  >
                    <mat-label>Paginas</mat-label>
                    <input
                      matInput
                      type              = "text"
                      formControlName   = "pages"
                      autocomplete      = "off"
                      placeholder       = "Separados por una coma(,)">
                      <mat-error> Campo obligatorio </mat-error>
                  </mat-form-field>
                </td>
                <td>
                  <mat-icon
                    (click) = "removeItem(i)"
                    class   = "deleteIcon"
                    >delete</mat-icon>

                  <button mat-icon-button
                      color   = "primary"
                      type    = "button"
                      title   = "delete item"
                      (click) = "removeItem(i)">
                    <i class="fi-rr-trash"></i>
                  </button>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        (click) = "addNewFilter()"
        class   = "col-12 mb-5 filtros d-flex justify-content-center"
        style   = "cursor: pointer;"
        >
          <span class="m-2">Añadir filtros </span>
          <mat-icon class="mt-1">add_circle</mat-icon>
      </div>
    </div>
  </form>

  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-end mb-5">
        <button
        mat-raised-button
        mat-dialog-close
        color    = "basic"
        routerLink="/assigns"
        class    = "mr-1 dialog-button"
        >
        Cancelar</button>
        <button
          mat-raised-button
          [color]    = "formAssign.invalid ? 'basic' : 'success'"
          (click)    = "save()"
          class      = "ml-1"
          [disabled] = "formAssign.invalid"
        >
        Guardar</button>
      </div>
    </div>
  </div>
</div>
