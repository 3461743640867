<h1>Consulta de dashboard</h1>

<p class="module-help-text" >
  En este espacio podrás revisar todos los dashboard que tengas <br>
  asignados. No olvides, “El respeto y empatía son clave”.
</p>


<div class="row mt-5">
  <div class="col-6">
    <mat-form-field appearance="fill">
      <mat-label>Dashboard</mat-label>
      <mat-select (selectionChange)="showReport($event.value)" [disabled]="selectReportsDis">
        <mat-option *ngFor="let dash of dashes" [value]="dash">
          {{dash.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="row">
  <div id="reportContainer" class="col-12" style="height: 95vh;">
  </div>
</div>
