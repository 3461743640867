import { Component, OnInit } from '@angular/core';
import {delay} from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  loading: boolean = false;
  title = 'soulbi-externo';


  constructor(
    public router: Router,
    private _loading: LoadingService
  ){
  }


  ngOnInit() {
    this.listenToLoading();
  }


  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }


}
