import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert-service.service';
import { CustomerService } from 'src/app/services/customer.service';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public hide      : boolean = true;
  public loginForm :FormGroup;


  constructor(
    private fb              :FormBuilder,
    private userService     : UserServiceService,
    private customerService : CustomerService,
    private alert           : AlertService,
    private router          : Router

  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      "username" : ['' , Validators.required],
      "password" : ['' , Validators.required]
    });
  }

  view_password(){
    this.hide = !this.hide;
  }


  login(){
    if( this.loginForm.invalid){
      return;
    }


    this.userService.login(this.loginForm.value).subscribe(res =>{
      localStorage.setItem("token",res.token);

      const system_key = res["system"].find( s => s["system_key"] == "SOUL_BI" );

      if( system_key && system_key["role"] &&  system_key["role"] == "user" ){
        this.customerService.getCollaboratorInfo(system_key["system_entity_id"]).subscribe( user =>{

          if (user.state == false ) {
            this.alert.error("Usuario inactivo");
            this.router.navigate(["/login"]);
          }

          localStorage.setItem("user",JSON.stringify(user));
        })
      }
      this.router.navigate(["/init"]);
    },
    error => {
      console.log("En situacion");
      this.alert.error("¡Usuario o contraseña incorrectos!")
    }
    )
  }



}
