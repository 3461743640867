<button class="close" mat-button mat-dialog-close>
  <img src="assets/images/close-modal.svg" alt="">
</button>

<div mat-dialog-content class="ml-5 mr-5">
  <h2 class="mt-3 mb-1">{{ customer == null ? "Crear" : "Editar" }} empresa</h2>
  <hr class="mt-0">
  <form class="mt-4" [formGroup]="formCompany" >
    <mat-form-field class="w-100" appearance="fill">
      <mat-label>Nombre de la empresa*</mat-label>
      <input
      matInput
      autocomplete    ="off"
      formControlName ="name"
      placeholder     ="Razón social"
      >
      <mat-error>Campo obligatorio</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="fill">
      <mat-label>Nit*</mat-label>
      <input
      matInput
      autocomplete    ="off"
      formControlName ="nit"
      placeholder     ="Nit"
      >
      <mat-error>Campo obligatorio</mat-error>
    </mat-form-field>

  </form>

  <div class="d-flex justify-content-center mt-5 mb-3">
    <button
          mat-raised-button
          mat-dialog-close
          color    = "basic"
          class    = "mr-1 dialog-button"
          >
          Cancelar</button>
    <button
          mat-raised-button
          [color]    = "formCompany.valid ? 'success' : 'basic'"
          [disabled] = "formCompany.invalid"
          (click)    = "saveCustomer()"
          class      = "ml-1 dialog-button"
          >
          Guardar</button>
  </div>
</div>

