import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(

  ) { }


  public success(title,msg){
    Swal.fire({
      icon             : 'success',
      title            : title,
      text             : msg,
      showConfirmButton: false,
      timer            : 2500
    })
  }


  public error(msg){
    Swal.fire({
      icon             : 'error',
      title            : '¡Atención!',
      showConfirmButton: false,
      timer            : 2500,
      text             : msg
    })
  }

  public confirm(title:string , msg:string ):Promise<any>{
    const swal = Swal.fire({
      title            : title,
      text             : msg,
      showDenyButton   : true,
      showCancelButton : false,
      confirmButtonText: 'Si',
      denyButtonText   : `No`,
    });
    return swal;
  }
}
