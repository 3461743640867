import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { ConfigDashboardInterface } from 'src/app/interfaces/ConfigDashboardInterface';
import { CustomerInterface } from 'src/app/interfaces/CustomerInterface';
import { DashboardInterface } from 'src/app/interfaces/DashboardInterface';
import { PaginatorInterface } from 'src/app/interfaces/PaginatorInterface';
import { AlertService } from 'src/app/services/alert-service.service';
import { CustomerService } from 'src/app/services/customer.service';
import { SoulBIService } from 'src/app/services/soulbi.service';
import {map, startWith} from 'rxjs/operators';
import { CollaboratorInterface } from 'src/app/interfaces/CollaboratorInterface';


@Component({
  selector: 'app-assigns',
  templateUrl: './assigns.component.html',
  styleUrls: ['./assigns.component.css']
})
export class AssignsComponent implements OnInit {

  findAsignForm     : FormGroup;
  filteredCustomers : Observable<CustomerInterface[]>;
  paginator         : PaginatorInterface;
  customers         : CustomerInterface[];
  collabs           : CollaboratorInterface[];
  asigns            : ConfigDashboardInterface[];
  options           : string[] = ['One', 'Two', 'Three'];
  filteredUsers     : Observable<CollaboratorInterface[]>;



  @ViewChild('paginatorEl') paginatorEl: MatPaginator;

  constructor(
    private soulbiService   : SoulBIService,
    private customerService : CustomerService,
    private fb              : FormBuilder,
    private alertService    : AlertService
  ) { }

  ngOnInit(): void {
    this.findAsignForm = this.fb.group({
      name        : [''],
      customer_id : [''],
      collab_id   : []
    });

    this.customerService.getAllCustomers().subscribe( (customers) => {
      this.customers = customers;
      this.filteredCustomers = this.findAsignForm.get("customer_id").valueChanges.pipe(
        startWith(''),
        map(value => this._filter_customer(value || '')),
      );
    } );


    this.customerService.getAllCollabs().subscribe( collabs=> {
      this.collabs = collabs;
      this.filteredUsers = this.findAsignForm.get("collab_id").valueChanges.pipe(
        startWith(''),
        map(value => this._filter_user(value || '')),
      );

    } )



    this.filterAsign();
  }

  /**
   * Filtra los usuarios dentro del autocomplete
   * @param value
   * @returns
   */
  private _filter_user(value: string): CollaboratorInterface[] {
    if( value == "" || typeof(value) == "number" ){
      return;
    }
    const filterValue = value.toLowerCase();
    return this.collabs.filter(option => {
      return option.first_name.toLowerCase().includes(filterValue)
      || option.last_name.toLowerCase().includes(filterValue)
      || option.id_number.toLowerCase().includes(filterValue)
    });
  }


  /**
   * Filtra un cliente cuyo nombre coincida con los del arreglo customers
   * @param value
   * @returns
   */
  private _filter_customer(value: string): CustomerInterface[] {
    if( value == "" || typeof(value) == "number" ){
      return;
    }
    const filterValue = value.toLowerCase();
    return this.customers.filter(cus => cus.name.toLowerCase().includes(filterValue));
  }

  /**
   * Retorna el nombre de un cliente dado el id
   * @param customerId
   * @returns
   */
  displayCustomer( customerId ): string{
    if( customerId == "" || this.customers == undefined ){
      return;
    }
    return this.customers.find( c => c.id == customerId ).name    ;
  }

  displayCollab( collabId ): string{
    if( collabId == "" || this.customers == undefined ){
      return;
    }
    const collab = this.collabs.find( c => c.id == collabId );
    return `${collab.first_name} ${collab.last_name}`    ;
  }



  /**
   * Consulta en el back las asignaciones, con los filtros
   * seleccionados si los hubiera
   */
  filterAsign(){
    const filter = this.findAsignForm.value;
    this.soulbiService.getAssig(filter, this.paginatorEl ).then(asigns =>{
      this.asigns     = asigns.data;
      this.paginator  = asigns;
    });
  }

  /**
   * Envía petición para el borrado de una configuración
   * @param assign
   */
  public delete( assign:ConfigDashboardInterface ){
    this.alertService.confirm("¡Atención!" , "¿Desea eliminar el registro?" ).then(result =>{
      if( result.isConfirmed ){
        this.soulbiService.deleteAssign( assign.id ).subscribe( res => this.filterAsign());
      }
    });
  }



}
