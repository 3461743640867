import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CollaboratorInterface } from 'src/app/interfaces/CollaboratorInterface';
import { CustomerInterface } from 'src/app/interfaces/CustomerInterface';
import { AlertService } from 'src/app/services/alert-service.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {


  customers                                 : CustomerInterface[];
  filteredCostumers                         : Observable<CustomerInterface[]>;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  showPasssword                             : boolean = false;

  formUser: FormGroup;
  constructor(
    private fb             : FormBuilder,
    private customerService: CustomerService,
    private alertService   : AlertService,
    private dialogRef      : MatDialogRef<EditUserComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {

    if( this.data ){
      const colllab: CollaboratorInterface = this.data["collaborator"]
      this.formUser = this.fb.group({
        customer_id: [ "" , Validators.required],
        first_name : [ colllab.first_name , Validators.required],
        last_name  : [ colllab.last_name , Validators.required],
        email      : [ colllab.email , [Validators.required , Validators.email ]],
        id_number  : [ colllab.id_number , [Validators.required , Validators.pattern("^[0-9]*$") ]],
        password   : [ '' , [Validators.required , Validators.minLength(6) ]],
        state      : [ colllab.state , Validators.required],
      });
      this.formUser.get("id_number").disable();
      this.formUser.get("password").disable();
    }
    else
    {
      this.formUser = this.fb.group({
        customer_id: ['' , Validators.required],
        first_name : ['' , Validators.required],
        last_name  : ['' , Validators.required],
        email      : ['' , [Validators.required , Validators.email ]],
        id_number  : ['' , [Validators.required , Validators.pattern("^[0-9]*$") ]],
        password   : ['' , [Validators.required , Validators.minLength(6)]],
      });
    }



    this.customerService.getAllCustomers().subscribe((customers:CustomerInterface[]) =>{
      this.customers = customers;
      this.filteredCostumers = this.formUser.get("customer_id").valueChanges.pipe(
        startWith(''),
        map(value => this._filter_costumer(value || '')),
      );

      if( this.data ){
        this.formUser.get("customer_id").setValue(this.data["collaborator"].customer_id )
      }
    })

  }



  private _filter_costumer(value: string): CustomerInterface[] {
    if( typeof(value) !== "string" ){
      return;
    }
    const filterValue = value.toLowerCase();
    return this.customers.filter(cus => cus.name.toLowerCase().includes(filterValue));
  }

  getCustomerName( customerId: number ){
    if( typeof(customerId) == "string" ){
      return;
    }
    return this.customers.find( cus => cus.id == customerId ).name;
  }

  ngAfterViewInit() {
    this.trigger.panelClosingActions.subscribe(e => {
      if (!(e && e.source)) {
        this.formUser.get("customer_id").setValue('');
        this.trigger.closePanel();
      }
    });
  }



  saveUser(){
    if( this.formUser.invalid ){
      return;
    }

    let action;
    if( this.data &&  "collaborator" in this.data ){
      action = this.customerService.editCollaborator( this.formUser.value , this.data["collaborator"].id );
    }
    else
    {
      action = this.customerService.saveCollaborator( this.formUser.value );
    }

    action.subscribe(res=>{
      this.dialogRef.close();
      this.alertService.success("¡Correcto!","Usuario creado");
    },
    error =>{
      if("errors" in error.error){
        this.alertService.error( JSON.stringify(error.error.errors) );
      }
      else
      {
        return this.alertService.error("No se pudo almacenar el registro.")
      }
    }
    )

  }


}
