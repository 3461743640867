import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { map,startWith } from 'rxjs/operators';
import { CustomerInterface } from 'src/app/interfaces/CustomerInterface';
import { DashboardInterface } from 'src/app/interfaces/DashboardInterface';
import { PaginatorInterface } from 'src/app/interfaces/PaginatorInterface';
import { AlertService } from 'src/app/services/alert-service.service';
import { CustomerService } from 'src/app/services/customer.service';
import { SoulBIService } from 'src/app/services/soulbi.service';
import { EditDashComponent } from './edit-dash/edit-dash.component';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.css']
})
export class DashboardsComponent implements OnInit {

  findDashForm                         :FormGroup;
  dashboards                           : DashboardInterface[];
  paginator                            : PaginatorInterface;
  customers                            : CustomerInterface[];
  filteredCustomers                    : Observable<CustomerInterface[]>;
  @ViewChild('paginatorEl') paginatorEl: MatPaginator;



  constructor(
    private dialg          :MatDialog,
    private fb             :FormBuilder,
    private customerService: CustomerService,
    private soulbiService  : SoulBIService,
    private alertService   : AlertService
  ) { }

  ngOnInit(): void {
    this.findDashForm = this.fb.group({
      name       : [],
      key        : [],

    });

    this.customerService.getAllCustomers().subscribe( (result:CustomerInterface[]) =>{
      this.customers = result;
    });
    this.filterDash();
  }

    /**
   * Filter customer from autocomplete
   * @param value customer name
   * @returns
   */
     private _filter_customer(value: string | CustomerInterface): CustomerInterface[] {
      if( typeof value !=="string" ){
        return;
      }
      const filterValue = value.toLowerCase();
      return this.customers.filter(cus => cus.name.toLowerCase().includes(filterValue));
    }


  filterDash(){
    const filter = this.findDashForm.value;
    this.soulbiService.getDash( filter , this.paginatorEl ).subscribe( (dash:PaginatorInterface) => {
      this.dashboards = dash.data;
      this.paginator =  dash;
    } )

  }

  /**
   * Abre moal para crear un nuevo dashboard
   */
  createDash(){
    const dialog = this.dialg.open(EditDashComponent);
    dialog.afterClosed().subscribe( res => {
      this.filterDash();
    })
  }

  /**
   * Abre modal para edición de un dashboard
   * @param dash
   */
  editDash( dash:DashboardInterface ){
    const dialog =this.dialg.open(EditDashComponent ,
      {
        data: {
          dashboard:dash
        }
      });

    dialog.afterClosed().subscribe( res => {
        this.filterDash();
      })
  }


  /**
   * Elimina un dashboard
   * @param dashboard
   */
  delete( dashboard:DashboardInterface ){
    this.alertService.confirm("¡Atención!","¿Realmente desea eliminar este registro?").then(
      result => {
        if(result.isConfirmed){
          this.soulbiService.deleteDash(dashboard.id).subscribe(res =>{
            this.alertService.success("¡Correcto!" , "Registro eliminado." );
            this.filterDash();
          },
          error =>{
            this.alertService.error("No fue posible eliminar el registro" )
          }
          )
        }
      },

    )

  }

}
