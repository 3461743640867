<!-- Loading spinner -->
<div *ngIf="loading" class="d-flex justify-content-center loading-container flex-content-center">
  <mat-progress-spinner
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
</div>

<!-- the application -->
<div class="parent">
  <app-menu *ngIf="router.url !='/login'" class="menu"></app-menu>
  <div [ngClass]="{'content ml-5 mt-3 pt-5':router.url !='/init'}">
    <router-outlet></router-outlet>
  </div>
</div>
