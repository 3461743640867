import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerInterface } from 'src/app/interfaces/CustomerInterface';
import { AlertService } from 'src/app/services/alert-service.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.css']
})
export class EditCompanyComponent implements OnInit {

  formCompany     : FormGroup;
  public customer : CustomerInterface = null;



  constructor(
    private fb                          : FormBuilder,
    private customerService             : CustomerService,
    private alertService                : AlertService,
    private dialogRef                   : MatDialogRef<EditCompanyComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if( data!== null ){
      this.customer = this.data["customer"];
    }
  }

  ngOnInit(): void {
    if( this.customer == null ){
      this.formCompany = this.fb.group({
        nit  : ['' , Validators.required ],
        name : ['' , Validators.required ]
      });
    }
    else
    {
      this.formCompany = this.fb.group({
        nit  : [ this.customer.nit , Validators.required ],
        name : [ this.customer.name , Validators.required ]
      });
    }
  }


  /**
   * Crea nuevo cliente
   * @returns
   */
  saveCustomer(){
    if( this.formCompany.invalid ){
      return;
    }

    const action = this.customer == null ? this.customerService.createCustomer( this.formCompany.value ) :this.customerService.editCustomer( this.formCompany.value , this.customer.id );

    action.subscribe(
      (res:CustomerInterface) => {
        this.alertService.success("¡Correcto!","Registro almacenado correctamente.")
        this.dialogRef.close();
      },
      error => {
        if("errors" in error.error){
          this.alertService.error( JSON.stringify(error.error.errors) );
        }
        else
        {
          return this.alertService.error("No se pudo almacenar el registro.")
        }

      }
    )




  }

}
