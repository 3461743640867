import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { CustomerInterface } from 'src/app/interfaces/CustomerInterface';
import { PaginatorInterface } from 'src/app/interfaces/PaginatorInterface';
import { AlertService } from 'src/app/services/alert-service.service';
import { CustomerService } from 'src/app/services/customer.service';
import { EditCompanyComponent } from './edit-company/edit-company.component';




@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {

  @ViewChild('paginatorEl') paginatorEl: MatPaginator;


  findCustomerForm : FormGroup;
  paginator        : PaginatorInterface;
  customers        : CustomerInterface[] = []

  constructor(
    private fb             : FormBuilder,
    private customerService: CustomerService,
    private dialog         : MatDialog,
    private alertService   : AlertService
   ) { }

  ngOnInit(): void {
    this.findCustomerForm = this.fb.group({
      "name" : [],
      "nit"  : [],
    });

    this.getCustomer();
  }

  /**
   * Consulta los clientes filtrando opcionalmente por nit o nombre
   * @param opc - "nit o name"
   * @param value - Valor a filtrar
   */
  public getCustomer( ){
    const filter = this.findCustomerForm.value;
    this.customerService.getAll(filter , this.paginatorEl ).subscribe((result:PaginatorInterface ) =>{
      this.customers = result.data;
      this.paginator = result;
    });
  }


  /**
   * Set filter by customer
   */
  filterCustomer(){
    this.paginatorEl.pageIndex = 0;
    this.getCustomer();
  }

  /**
   * Abre dialog para crear cliente
   */
  createCompany(){
    const dialog = this.dialog.open( EditCompanyComponent,{
      width : '50%'
    } );

    dialog.afterClosed().subscribe(res => {
      this.getCustomer();
    })
  }

  /**
   * Abre dialog para modificación de cliente
   * @param customer
   */
  editCompany( customer:CustomerInterface ){
    const dialog = this.dialog.open( EditCompanyComponent,{
      width : '50%',
      data:{
        customer: customer
      }
    });
    dialog.afterClosed().subscribe(res => {
      this.getCustomer();
    })
  }


  public delete(customer:CustomerInterface){
    this.alertService.confirm("¡Atención!",`¿Desea eliminar el cliente ${customer.name.toUpperCase()}?`).then(res=>{
      if( res["isConfirmed"] ){
        this.customerService.deleteCustomer(customer.id).subscribe(
          res   => this.getCustomer(),
          error => {
            this.alertService.error("No se pudo eliminar el registro")
          },
          )
      }
    })  ;

  }



}
