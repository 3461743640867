import { Component, OnInit } from '@angular/core';
import { CustomerInterface } from 'src/app/interfaces/CustomerInterface';
import { CollaboratorInterface } from 'src/app/interfaces/CollaboratorInterface';
import { DashboardInterface } from 'src/app/interfaces/DashboardInterface';
import { AlertService } from 'src/app/services/alert-service.service';
import { SoulBIService } from 'src/app/services/soulbi.service';
import * as pbi from 'powerbi-client';
import { ConfigDashboardInterface } from 'src/app/interfaces/ConfigDashboardInterface';



@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  dashes           : DashboardInterface[];
  selectReportsDis : boolean = false;


  constructor(
    private soulbiService   : SoulBIService,
    private alertService    : AlertService
  ) { }

  ngOnInit(): void {
    const collab:CustomerInterface = JSON.parse(localStorage.getItem("user")) as CustomerInterface ;
    this.soulbiService.getUserReports(collab.id, collab.customer_id).subscribe( dashes => {
      this.dashes = dashes;
    });
  }

  private buildFilter(f) : pbi.models.IBasicFilter {
    return {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: f.table,
        column: f.column
      },
      operator: "In",
      values: f.values,
      filterType: 1
    }
  }

  showReport(report) {
    const user: CollaboratorInterface = JSON.parse(localStorage.getItem("user")) ;
    const dashConfig : ConfigDashboardInterface = report.configs[0];
    this.soulbiService.getToken(report.key , user.id ).subscribe( resp =>{
      if( resp.data["token"] === undefined ){
        this.alertService.error("No es posible visualizar este reporte");
        return;
      }



      this.selectReportsDis = true;
      //Elimina contendio del div


      const tokenReport = resp.data.token;
      const reportId =resp.data.reporte_id;


      if (tokenReport!==''){


          let reportContainer         = <HTMLElement>document.getElementById('reportContainer');
          let embedUrl                = 'https://app.powerbi.com/reportEmbed?';
          let powerbi                 = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
          powerbi.reset(reportContainer);
          let config= {
            type                      : 'report',
            accessToken               : tokenReport,
            embedUrl                  : embedUrl,
            id                        : reportId,
            settings: {
              filterPaneEnabled       : false,
              navContentPaneEnabled   : true
            }
          };
          const report: pbi.Report = <pbi.Report>(powerbi.embed(reportContainer, config));

          dashConfig.filters.map( f =>{
            f.values = JSON.parse(f.values);
            f.pages = JSON.parse(f.pages);
          } )




          const test = [];
          report.on('loaded', () => {

            //Filtros por páginas
            report.getPages().then( pages => pages.forEach(p => {
              const filters = dashConfig.filters.filter( f => f.pages.includes(p.displayName) )
                                .map( f => this.buildFilter(f));
              p.setFilters(filters);
            }) );

            // Filtros generales
            const filters = dashConfig.filters.filter( f => f.pages.filter( p => p == "" ).length == f.pages.length )
              .map(f => this.buildFilter(f));
            report.setFilters(filters);
          });


        }

    });

  }

}
