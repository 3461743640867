import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingService } from './loading.service';
import { catchError , map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService } from './alert-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private _loading    : LoadingService,
    private router      : Router,
    private alertService: AlertService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = localStorage.getItem('token');
    let request = req;
    this._loading.setLoading(true, request.url);

    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`
        }
      });
    }



    return next.handle(request).pipe(
      catchError((err) => {
        this._loading.setLoading(false, request.url);

        if( this.router.url == "/login" ){
          return next.handle(req);
        }
        else if( err.status == 401 ){
          this.alertService.error("La sesión expiró");
          this.router.navigate(["/login"]);
          return Observable.throw(null);
        }
        return throwError(err);
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this._loading.setLoading(false, request.url);
        }
        return evt;
      }));;


  }

}
