// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // AUTH_BACK_URL    : "http://localhost:8005/api/",
    // CUSTOMER_BACK_URL: "http://localhost:8011/api/",
    // SOULBI_URL       : "http://localhost:8013/api/", 
    // AUTH_BACK_URL    : "https://prod-auth.groupcos.com/api/",
    // CUSTOMER_BACK_URL: "https://backendcustomers.groupcos.com/api/",
    // SOULBI_URL       : "https://backendreportes.groupcos.com/api/",

    AUTH_BACK_URL    : "https://apigateway.outsourcingcos.com/v1/ms/mios/auth/api/",
    CUSTOMER_BACK_URL: "https://apigateway.outsourcingcos.com/v1/ms/customers/api/",
    SOULBI_URL       : "https://apigateway.outsourcingcos.com/v1/ms/reportes/api/",
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.