import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardInterface } from 'src/app/interfaces/DashboardInterface';
import { AlertService } from 'src/app/services/alert-service.service';
import { SoulBIService } from 'src/app/services/soulbi.service';

@Component({
  selector: 'app-edit-dash',
  templateUrl: './edit-dash.component.html',
  styleUrls: ['./edit-dash.component.css']
})
export class EditDashComponent implements OnInit {

  formDash:FormGroup;


  constructor(
    private fb              :FormBuilder,
    private dashboardService: SoulBIService,
    private alertService    : AlertService,
    private dialog          : MatDialogRef<EditDashComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if( this.data === null ){
      this.formDash = this.fb.group({
        name       : ['',Validators.required],
        key        : ['',Validators.required],
        report_id  : ['',Validators.required]
      });
    }
    else
    {
      this.formDash = this.fb.group({
        name       : [ this.data["dashboard"].name ,Validators.required],
        key        : [ this.data["dashboard"].key ,Validators.required],
        report_id  : [ this.data["dashboard"].report_id ,Validators.required]
      });
    }
  }


  /**
   * Almacena un nuevo dashboard
   */
  saveDash(){
    const action = this.data !== null ? this.dashboardService.editDash( this.formDash.value , this.data["dashboard"].id ) : this.dashboardService.saveDash( this.formDash.value );
    action.subscribe( (res:DashboardInterface)=>{
      this.alertService.success("¡Correcto!","Registro almacenado");
      this.dialog.close();
    } ,
    error=>{
      this.alertService.error( JSON.stringify(error.error));
    }

    );
  }

}
