<div class="row">
  <div class="col-4 mt-5 pt-5 pl-5">
    <p class="letter-spacing">SOUL BI</p>
    <h1>¡Bienvenid@ {{name}} !</h1>
    <p class="text mt-5">Este es el módulo de <b>SOUL BI</b> en donde podrás administrar
      los dashboards y vivas la mejor experiencia haciendo seguimiento de tus indicadores
      en una sola herramienta.</p>
    <button
          mat-raised-button
          class = "mt-5"
          color = "white"
          >
          Quiero ver el onboarding</button>
  </div>

  <div class="col-8 d-flex justify-content-end">
    <img src="assets/images/init.svg" alt="">
  </div>
</div>
