<h1>Administración de usuarios</h1>

<p class="module-help-text" >
  En este espacio podrás revisar y/o crear usuarios. <br>
  No olvides, “El respeto y empatía son clave”.
</p>


<div class="row mt-5">

  <div class="col-md-7 col-sm-12">
    <form [formGroup]="findUserForm">
    <mat-form-field appearance="fill" class="mr-4">
      <mat-label>Usuario o identificación</mat-label>
      <input
        matInput
        type            ="text"
        formControlName ="name"
        placeholder     ="Usuario o identificación"
        autocomplete    ="off"
        (keyup.enter)   = "filterUser()"
        (blur)          = "filterUser()"
        >
      <mat-icon
        matSuffix
        style             ="cursor: pointer;"
        (click)           ="filterUser()"
          >search</mat-icon>
    </mat-form-field>

    <mat-form-field  appearance="fill">
      <mat-label>Cliente</mat-label>
      <input
        matInput
        type              = "text"
        placeholder       = "Seleccione un cliente"
        formControlName   = "customer_id"
        [matAutocomplete] = "auto"

        >
      <mat-autocomplete
        autoActiveFirstOption
        #auto           ="matAutocomplete"
        (optionSelected)="filterUser()"
        [displayWith]   ="getCustomerName.bind(this)"
        >

        <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
          {{customer.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  </div>

  <div class="col-md-5 col-sm-12" style="text-align: right;">
    <button
      mat-raised-button
      (click) = "createUser()"
      color   = "success">
        Crear usuario
      <mat-icon>add_circle</mat-icon>
    </button>
  </div>
</div>
<div class="row mt-4">
  <div class="col-12">
    <table mat-table [dataSource]="collaborators" class="w-100">

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            class   = "m-1 material-icons-outlined"
            (click) = "editUser(element)"
            >edit</mat-icon>

          <mat-icon
          (click) = "modalResetPassword(element)"
          >
            lock_reset
          </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{ element.first_name + " " + element.last_name  }} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <ng-container matColumnDef="id_number">
        <th mat-header-cell *matHeaderCellDef> Identificación </th>
        <td mat-cell *matCellDef="let element"> {{element.id_number }} </td>
      </ng-container>

      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef> Cliente </th>
        <td mat-cell *matCellDef="let element"> {{element.customers.name }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>

<div class="row mt-3 mb-2" *ngIf="paginator">
  <mat-paginator
    #paginatorEl
    [length]          = "paginator.total"
    [pageSize]        = "paginator.per_page"
    [pageSizeOptions] = "[8,20,100]"
    aria-label        = "Seleccione una página"
    (page)            = "pageEvent = getCollaborators($event)"

      >
  </mat-paginator>
</div>


