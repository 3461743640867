import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomerInterface } from '../interfaces/CustomerInterface';
import { catchError, map } from 'rxjs/operators';
import { PaginatorInterface } from '../interfaces/PaginatorInterface';
import { MatPaginator } from '@angular/material/paginator';
import { CollaboratorInterface } from '../interfaces/CollaboratorInterface';




@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private url = environment.CUSTOMER_BACK_URL;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Consulta  los clientes
   * @returns listado de clientes
   */
   public getAll( filter:any = {} , pagine ):Observable<PaginatorInterface>{
    let params = new HttpParams();

    // Filtro por cliente
    if( filter.nit != null && filter.nit !== "" ){
      params = params.append('nit', filter.nit );
    }
    if( filter.name != null && filter.name !== "" ){
      params = params.append('name', filter.name );
    }

    if( pagine && Number.isInteger(pagine.pageIndex) ){
      params = params.append('page', (pagine.pageIndex + 1).toString() );
    }


    if( pagine && Number.isInteger(pagine.pageSize ) ){
      params = params.append('size', (pagine.pageSize).toString() );
    }
    return this.http.get( `${this.url}customer` ,  { params: params } )
    .pipe(map((res:PaginatorInterface) => res ))
  }

  /**
   * Get all customers
   * @returns
   */
  public getAllCustomers(): Observable<| CustomerInterface[]> {
    return this.http.get( `${this.url}customer/get_all` )
      .pipe(map((res:CustomerInterface[]) => res ))
  }


  /**
   * Get all collaborators
   * @returns
   */
   public getAllCollabs(): Observable<| CollaboratorInterface[]> {
    return this.http.get( `${this.url}collaborator/get_all` )
      .pipe(map((res:CollaboratorInterface[]) => res ))
  }

  /**
   * Crea un nuevo cliente
   * @param data
   * @returns
   */
  public createCustomer(data):Observable<CustomerInterface> {
    return this.http.post(`${this.url}customer` , data )
      .pipe(
        map( (res:CustomerInterface) => res )
        );
  }


  /**
   * Crea un nuevo cliente
   * @param data
   * @returns
   */
   public editCustomer(customer , id:number):Observable<CustomerInterface> {
    return this.http.put(`${this.url}customer/${id}` , customer )
      .pipe(map( (res:CustomerInterface) => res ));
  }

  /**
   * Delete an especific customer
   * @param id
   * @returns
   */
  public deleteCustomer(id:number):Observable<any> {
    return this.http.delete(`${this.url}customer/${id}`);
  }



  /**
   * Consulta los colaboradores asociados a los clientes
   * @returns
   */
  public getCollaborators(filter:any = {} , pagine:MatPaginator):Observable<PaginatorInterface>{
    let params = new HttpParams();

    if( filter.name != null && filter.name !== "" ){
      params = params.append('name', filter.name );
    }

    if( filter.customer_id != null && filter.customer_id !== "" ){
      params = params.append('customer_id', filter.customer_id );
    }

    if( pagine && Number.isInteger(pagine.pageIndex) ){
      params = params.append('page', (pagine.pageIndex + 1).toString() );
    }
    if( pagine && Number.isInteger(pagine.pageSize ) ){
      params = params.append('size', (pagine.pageSize).toString() );
    }

    return this.http.get(`${this.url}collaborator`, { params:params })
      .pipe(map( (colaborators:PaginatorInterface) => colaborators ))
  }

  /**
   * Almacena un nuevo colaborador
   * @param params
   * @returns
   */
  public saveCollaborator(params):Observable<CollaboratorInterface> {
    return this.http.post(`${this.url}collaborator`, params)
    .pipe(map( (colaborators:CollaboratorInterface) => colaborators ));
  }

  /**
   * Editar un colaborador
   * @param params  parametros a editar
   * @param collabId  id del colaborador
   * @returns
   */
  public editCollaborator(params, collabId:number):Observable<CollaboratorInterface> {
    return this.http.put(`${this.url}collaborator/${collabId}`, params)
    .pipe(map( (colaborators:CollaboratorInterface) => colaborators ));
  }

  /**
   * Elimina un colaborador
   * @param collab colaborador a eliminar
   * @returns
   */
  public deleteCollaborator( collab:CollaboratorInterface ): Observable<any>{
    return this.http.delete(`${this.url}collaborator/${collab.id}`);
  }

  /**
   * Consulta la información del colaborador
   * @param collabId
   * @returns
   */
  public getCollaboratorInfo( collabId ):Observable<CollaboratorInterface> {
    return this.http.get(`${this.url}collaborator/${collabId}`)
      .pipe( map( (c:CollaboratorInterface) => c ));
    ;

  }


}
