import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInterface } from '../interfaces/UserInterface';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  private url = environment.AUTH_BACK_URL;

  constructor(
    private http: HttpClient
  ) { }


  /**
   * Inicio de sesión
   * @param username nombre de usuario
   * @param password password
   * @returns
   */
  public login( data:UserInterface ):Observable<any>{
    return this.http.post( `${this.url}user/login`,data)
  }

  public logout() : Observable<any>{
    return this.http.get(`${this.url}user/logout`);
  }

  /**
   * Verifica el estado de conexión del usaurio con el token activo
   * @returns
   */
  public userStatusConection():Observable<any>{
    return this.http.get( `${this.url}user/getUser`)
  }

  /**
   * Genera un token para reestablecer la contraseña
   * @param username nombre de usuario a reestablecer
   * @returns
   */
  public getResetToken( username ) :Observable<any> {
    return this.http.post(`${this.url}user/externalToken` , {service: "PORTAL" , username :  username});
  }

  /**
   * Reestablece la contraseña de un usuario
   * @param token
   * @param newPassword
   * @returns
   */
  public resetPassword( token, newPassword  ) : Observable<any> {
    return this.http.post(`${this.url}user/resetExternalPassword` , {token: token , password: newPassword });
  }



}
