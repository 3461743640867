import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserServiceService } from 'src/app/services/user-service.service';


interface itemsMenu{
  link : string;
  text : string;
  icon : string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  public collapse = true;
  public items:itemsMenu[] ;

  constructor(
    public router       : Router,
    private userService : UserServiceService
  ) { }

  ngOnInit(): void {
    this.userService.userStatusConection().subscribe( user => {
      if( user["role"]== "admin" ){
        this.items = [
          {link: "/init"   ,icon:"home",text:"Inicio"},
          {link: "/company"   ,icon:"domain",text:"Empresas"},
          {link: "/users"   ,icon:"settings",text:"Usuarios"},
          {link: "/dashboards",icon:"post_add",text:"Dashboards"},
          {link: "/assigns"   ,icon:"visibility",text:"Asignaciones"}
        ];
      }
      else if(user["role"]== "user"){
        this.items = [
          {link: "/report" , icon:"monitoring",text:"Dashboards"}
        ];
      }
    })

  }

  public collapseMenu( opc:boolean ){
    this.collapse = opc;
  }

  public logout(){
    this.userService.logout().subscribe(()=> this.router.navigate(["/login"]) );
  }

}
