<button class="close" mat-button mat-dialog-close>
  <img src="assets/images/close-modal.svg" alt="">
</button>

<div mat-dialog-content class="ml-5 mr-5">
  <h2 class="mt-3 mb-1"> Restaurar contraseña </h2>
  <hr class="mt-0">
  <form class="mt-4" [formGroup]="formPassword" >
    <mat-form-field class="example-full-width w-100" appearance="fill">
      <mat-label>Nueva contraseña</mat-label>
      <input
        matInput
        formControlName = "password"
        [type]          = "showPassword ? 'text' : 'password'"
        placeholder     = "Digite la nueva contraseña"
        autocomplete    = "off"
        >

        <mat-icon
              matSuffix
              *ngIf="showPassword"
              (click)="showPassword = !showPassword"
                >visibility</mat-icon>

        <mat-icon
              matSuffix
              *ngIf   = "!showPassword"
              (click) = "showPassword = !showPassword"
                >visibility_off</mat-icon>
        <mat-error
          *ngIf="formPassword.controls['password'].errors?.required" >
            Campo obligatorio
        </mat-error>
        <mat-error
          *ngIf="formPassword.controls['password'].errors?.minlength" >
            Mínimo 6 caracteres requeridos</mat-error>
    </mat-form-field>
  </form>

  <div class="d-flex justify-content-center mt-5 mb-3">
    <button
          mat-raised-button
          mat-dialog-close
          color    = "basic"
          class    = "mr-1 dialog-button"
          >
          Cancelar</button>
    <button
          mat-raised-button
          (click)    = "resetPassword()"
          [color]    = "formPassword.valid ? 'success' : 'basic'"
          [disabled] = "formPassword.invalid"
          class      = "ml-1 dialog-button"
          >
          Guardar</button>
  </div>
</div>

