<h1>Asignación de dashboards</h1>
<p class="module-help-text">
  En este espacio podrás revisar y/o crear dashboards. <br>
  No olvides, “El respeto y empatía son clave”.
</p>


<div class="row mt-5">
  <div class="col-md-7 col-sm-12 findCustomerForm">
    <form [formGroup]="findAsignForm" class="">
      <mat-form-field appearance="fill" class="mr-3">
        <mat-label>Nombre del dashboard</mat-label>
        <input
        matInput
        type            = "text"
        formControlName = "name"
        placeholder     = "Nombre del dashboard o parte de él."
        autocomplete    = "off"
        (keyup.enter)   = "filterAsign()"
        (blur)          = "filterAsign()"
        >

        <mat-icon
        matSuffix
        style             ="cursor: pointer;"
        (click)           ="filterAsign()"
        >search</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill" class="mr-3" >
        <mat-label>Cliente</mat-label>
        <input
          matInput
          type              = "text"
          placeholder       = "Seleccione un cliente"
          formControlName   = "customer_id"
          [matAutocomplete] = "auto" >

        <mat-autocomplete
          autoActiveFirstOption
          #auto            = "matAutocomplete"
          [displayWith]    = "displayCustomer.bind(this)"
          (optionSelected) = "filterAsign()"
          >

          <mat-option *ngFor="let cus of filteredCustomers | async" [value]="cus.id">
            {{cus.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Usuario</mat-label>
        <input
          matInput
          type              = "text"
          formControlName   = "collab_id"
          placeholder       = "Usuarios"
          [matAutocomplete] = "auto_collab"
          >
        <mat-autocomplete
          autoActiveFirstOption
          #auto_collab      = "matAutocomplete"
          [displayWith]     = "displayCollab.bind(this)"
          (optionSelected)  = "filterAsign()"
          >
          <mat-option *ngFor="let user of filteredUsers | async" [value]="user.id">
            {{user.first_name + " " + user.last_name}}
            <span class="text-muted"> {{ user.id_number }} </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

  </form>
</div>

<div class="col-md-5 col-sm-12" style="text-align: right;">
  <button
    mat-raised-button
    routerLink="/assigns/create"
    color   = "success">
      Crear asignacion
    <mat-icon>add_circle</mat-icon>
  </button>
</div>


</div>



<div class="row mb-5">
  <div class="col-md-3 mt-5 mb-5" *ngFor="let dash of asigns , let idx = index ">
    <div class="card-icon d-flex justify-content-center">
      <mat-icon>description</mat-icon>
    </div>
    <mat-card style="height: 115%;">
      <mat-card-subtitle class="d-flex justify-content-center"> Dashboard</mat-card-subtitle>
      <mat-card-title class="mb-4"> {{ dash.dashboard.name }} </mat-card-title>

      <mat-card-content class="mb-4">
        <div *ngIf="dash.customers.length > 0">
          <p><b>Asignación por: </b>
            {{ dash.users.length >  0 ? "Usuarios" : "Empresa" }}
          </p>
          <p><b>Empresa: </b></p>
          <ul>
            <li *ngFor="let col of dash.customers">
              {{col.name}}
            </li>
          </ul>
          <div *ngIf="dash.users.length > 0">
            <p><b>Asignado a: </b></p>
            <ul>
              <li *ngFor="let col of dash.users">
                {{col.first_name}} {{col.last_name}} (Doc: {{col.id_number}})
              </li>
            </ul>
          </div>
        </div>

      </mat-card-content>
      <mat-card-actions class="mb-1" style="width: 100%; text-align: center; position: absolute; bottom: 1em; ">
        <hr class="mt-4 mr-4">
        <button
        mat-raised-button
        [routerLink]="'/assigns/'+dash.id"
        color = "link">
        <mat-icon>edit_square</mat-icon>
        Asignar
      </button>
      <button
      mat-raised-button
      color   = "link"
      (click) = "delete(dash)"
      >
      <mat-icon>delete</mat-icon>
      Eliminar
    </button>
  </mat-card-actions>

</mat-card>
</div>
</div>

<div class="row" *ngIf="paginator">
  <mat-paginator
  #paginatorEl
  [length]          ="paginator.total"
  [pageSize]        ="paginator.per_page"
  [pageSizeOptions] ="[8,20,100]"
  aria-label        ="Seleccione una página"
  (page)            = "pageEvent = filterAsign($event)"

  >
</mat-paginator>
</div>

