import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { CollaboratorInterface } from 'src/app/interfaces/CollaboratorInterface';
import { ConfigDashboardInterface } from 'src/app/interfaces/ConfigDashboardInterface';
import { CustomerInterface } from 'src/app/interfaces/CustomerInterface';
import { DashboardInterface } from 'src/app/interfaces/DashboardInterface';
import { PaginatorInterface } from 'src/app/interfaces/PaginatorInterface';
import { AlertService } from 'src/app/services/alert-service.service';
import { CustomerService } from 'src/app/services/customer.service';
import { SoulBIService } from 'src/app/services/soulbi.service';

@Component({
  selector: 'app-edit-assign',
  templateUrl: './edit-assign.component.html',
  styleUrls: ['./edit-assign.component.css']
})
export class EditAssignComponent implements OnInit {

  formAssign         : FormGroup;
  filteredDashboards : Observable<DashboardInterface[]>;
  assign             : ConfigDashboardInterface;
  dashboards         : DashboardInterface[] = [];
  customers          : CustomerInterface[];
  customersSel       : CustomerInterface[]     = [];
  customersUnSel     : CustomerInterface[];
  collabs            : CollaboratorInterface[];
  collabsSel         : CollaboratorInterface[] = [];
  collabsUnSel       : CollaboratorInterface[];
  filteredCustomers  : Observable<CustomerInterface[]>;
  dashId             : number;
  filteredDashes     : Observable<DashboardInterface[]>;

  constructor(
    private fb              : FormBuilder,
    private soulbiService   : SoulBIService,
    private customerService : CustomerService,
    private router          : ActivatedRoute,
    private route           : Router,
    private alertService    : AlertService
  ) { }

  ngOnInit(): void {
    let filter = {};
    this.formAssign = this.fb.group({
      customer       : [''  ],
      dashboard      : ['' , Validators.required ],
      type_selection : ['' , Validators.required ],
      collabsUnsel   : [''],
      collabsSel     : [''],
      customersUnsel : [''],
      customersSel   : [''],
      filters        : this.fb.array([]),

    });


    this.soulbiService.getAllDashes().subscribe( (dashes:DashboardInterface[]) =>{
      this.dashboards = dashes;
    } );

    this.filteredDashes = this.formAssign.get("dashboard").valueChanges.pipe(
      startWith(''),
      map(value => this._filter_dashes(value || '')),
    );

    this.router.params.subscribe(params => {
      this.dashId = params.id;


      this.soulbiService.getAssig({id: params.id } , null).then( (dashes:PaginatorInterface) => {
        this.assign = dashes.data[0];

        if( this.assign ){
          if( this.assign.users.length > 0 ){
            this.formAssign.get("type_selection").setValue("1");
            this.formAssign.get("customer").setValue( this.assign.customers[0].id );
          }
          else if( this.assign.customers.length > 0 )
          {
            this.formAssign.get("type_selection").setValue("2");
          }
          if( this.assign.customers.length > 0 ){
            filter = {customer_id:  this.assign.customers[0].id };
          }
          this.customerService.getCollaborators( filter , null ).subscribe( (collabs) => {
            this.collabs = collabs.data["collaborators"];
          });

          this.getCollabs(this.assign.customers[0].id);
          this.formAssign.get("dashboard").setValue(this.assign.dashboard.id);

          this.assign.filters.forEach( f => this.addNewFilter(f) );

          //Selected customers
          this.customersSel = this.assign.customers;
          this.customersUnSel = this.customers.filter( c => !this.customersSel.map(c=> c.id).includes(c.id) );
          this.customers.map( c => c.isSelected = this.customersSel.map(c => c.id).includes(c.id) )


        }
      } );
    });


    this.customerService.getAllCustomers().subscribe( (customers:CustomerInterface[]) => {
      this.customers = customers;
    } );

    this.filteredCustomers = this.formAssign.get("customer").valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  /**
   * Filtra en el mat autocomplete de dashboards
   * @param value
   * @returns
   */
  private _filter_dashes(value: string): DashboardInterface[] {
    if( typeof(value) !== "string" ){
      return;
    }
    const filterValue = value.toLowerCase();
    return this.dashboards.filter((dash:DashboardInterface) => dash.name.toLowerCase().includes(filterValue));
  }


  /***
   * Retorna el nombre de un dashboard dado un id
   */
  displayDash( dash ){
    if(dash == ""){
      return;
    }
    return this.dashboards.find(d => d.id == dash).name;
  }


  /**
   * Consulta los colaboradores relacionados a un cliente
   * @param customerId
   */
  getCollabs(customerId:number){
    this.customerService.getCollaborators({customer_id: customerId} , null).subscribe( (collabs:PaginatorInterface ) => {
      this.collabs = collabs.data;
      if( this.assign )
      {
        //Selected users
        this.collabsSel  = this.assign.users;
        this.collabsUnSel = this.collabs.filter( c => !this.collabsSel.map(c=> c.id).includes(c.id) );
        this.collabs.map(c => c.isSelected =  this.collabsSel.map( c=> c.id ).includes(c.id) );
      }
      else
      {
        this.collabsUnSel = this.collabs;
      }
    });
  }


  /**
   * Acción de pasar usuarios de la lista de disponibles a la de asignadps
   * @param opc: izquierda o derecha
   */
  public passUsers( opc:string ){
    let selectedItems;
    if( opc == "left" ){
      selectedItems = this.formAssign.get("collabsSel").value;
      this.collabs.map( c => selectedItems.includes(c.id) ? c.isSelected = false : null );
    }
    else if( opc == "right" )
    {
      selectedItems = this.formAssign.get("collabsUnsel").value;
      this.collabs.map( c => selectedItems.includes(c.id) ? c.isSelected = true : null );
    }
    this.collabsUnSel = this.collabs.filter( c => !c.isSelected );
    this.collabsSel = this.collabs.filter( c => c.isSelected );
  }


  /**
   * Acción de pasar clientes de la lista de disponibles a la de asignadps
   * @param opc: izquierda o derecha
   */
  public passCustomer( opc:string ){
    let selectedItems;
    if( opc == "left" ){
      selectedItems = this.formAssign.get("customersSel").value;
      this.customers.map( c => selectedItems.includes(c.id) ? c.isSelected = false : null );
    }
    else if( opc == "right" )
    {
      selectedItems = this.formAssign.get("customersUnsel").value;
      this.customers.map( c => selectedItems.includes(c.id) ? c.isSelected = true : null );
    }
    this.customersUnSel = this.customers.filter( c => !c.isSelected );
    this.customersSel   = this.customers.filter( c => c.isSelected );
  }


  /**
   * Carga los clientes en el arreglo que los permite visualziar en los disponibles
   * cuando se seleccione la opción "clientes"
   * @param opc
   * @returns
   */
  public getCustomers(opc){
    if( opc != "2" ){
      return;
    }
    this.customersUnSel = this.customers;
  }



  /**
   * Filtra el texto digitado en el campo custoemr
   * @param value
   * @returns
   */
  private _filter(value: string): CustomerInterface[] {
    if( typeof(value) !== "string" ){
      return;
    }
    const filterValue = value.toLowerCase();
    return this.customers.filter( (cus:CustomerInterface ) => cus.name.toLowerCase().includes(filterValue));
  }

  /**
   * Muestra el nombre del cliente dado un id
   * @param customerId
   * @returns
   */
  displayCustomer(customerId:number):string {
    if( typeof(customerId) == "string" ){
      return;
    }
    return this.customers.find( cus => cus.id == customerId ).name;
  }


  /**
   * Añade un nuevo grupo de campos para establecer un filtro
   */
  addNewFilter( filter = null ){
    const controls = this.formAssign.controls['filters'] as FormArray;
    let formGroup;
    if(filter == null ){
      formGroup = this.fb.group({
        id      : [ '' ],
        table   : [ '' , [Validators.required]],
        column  : [ '' , Validators.required],
        values  : [ '' , Validators.required],
        pages   : [ '']
      })
    }
    else
    {


      formGroup = this.fb.group({
        id      : [ filter.id ],
        table   : [ filter.table , [Validators.required]],
        column  : [ filter.column , Validators.required],
        values  : [ JSON.parse(filter.values).join(",")  , Validators.required],
        pages   : [ JSON.parse(filter.pages).join(",") ]
      });
    }
    controls.push(formGroup);

  }

  /**
   * Retorna el control de filtros
   * @returns
   */
  getFiltersControls(){
    return (this.formAssign.get('filters') as FormArray).controls;
  }

  /**
   * Remueve un item de los filtros
   * @param i
   */
  removeItem(i: number) {
    const controls = <FormArray>this.formAssign.controls['filters'];
    controls.removeAt(i);
  }


  save(){
    if( this.formAssign.invalid ){
      return;
    }

    if( this.collabsSel.length == 0 && this.customersSel.length == 0){
      this.alertService.error("Debe algun cliente o usuario.");
      return;
    }



    const filters = this.formAssign.get("filters").value ;
    filters.map( f => f["pages"] = f["pages"].split(",") )
    filters.map( f => f["values"] = f["values"].split(",") )

    const customers = this.collabsSel.length > 0 ?  [this.formAssign.get("customer").value] : this.customersSel.map( c => c.id ) ;

    const data = {
      dash_id   : this.formAssign.get("dashboard").value,
      users     : this.formAssign.get("type_selection").value==1 ? this.collabsSel.map( c => c.id ) : [] ,
      customers : customers ,
      filters   : filters
    }

    let action;
    if( this.assign){
      action = this.soulbiService.updateAssign(data , this.assign.id );
    }
    else
    {
      action = this.soulbiService.saveAssign(data);
    }

    action.subscribe( (cnf:ConfigDashboardInterface ) => {
      this.alertService.success("¡Correcto!","Registro creado correctamente.");
      this.route.navigate([`/assigns`]);

    },
    error =>{
      this.alertService.error("No fue posible eliminar el registro,")
    }
     )



  }





}
