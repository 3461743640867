<h1>Administración de dashboards</h1>
<p class="module-help-text">
  En este espacio podrás revisar y/o crear dashboards. <br>
  No olvides, “El respeto y empatía son clave”.
</p>


<div class="row mt-5">
  <div class="col-md-7 col-sm-12 findCustomerForm">
    <form [formGroup]="findDashForm" class="">
      <mat-form-field appearance="fill" class="mr-2">
        <mat-label>Nombre</mat-label>
        <input
          matInput
          type            = "text"
          formControlName = "name"
          placeholder     = "Nombre del dashboard"
          autocomplete    = "off"
          (keyup.enter)   = "filterDash()"
          (blur)          = "filterDash()"
        >

        <mat-icon
          matSuffix
          style             ="cursor: pointer;"
          (click)           ="filterDash()"
          >search</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="fill" >
        <mat-label>Llave</mat-label>
        <input
          matInput
          type            = "text"
          formControlName = "key"
          placeholder     = "Llave del dashboard"
          autocomplete    = "off"
          (keyup.enter)   = "filterDash()"
          (blur)          = "filterDash()"
        >

        <mat-icon
          matSuffix
          style             ="cursor: pointer;"
          (click)           ="filterDash()"
          >search</mat-icon>
      </mat-form-field>


  </form>
</div>

<div class="col-md-5 col-sm-12" style="text-align: right;" >
  <button
  mat-raised-button
  (click)="createDash()"
  color = "success">
  Crear dashboard
  <mat-icon>add_circle</mat-icon>
</button>
</div>
</div>



<div class="row mb-5">
  <div class="col-md-3 mt-5 mb-5" *ngFor="let dash of dashboards , let idx = index ">
    <div class="card-icon d-flex justify-content-center">
      <mat-icon>description</mat-icon>
    </div>
    <mat-card style="height: 130%;">
      <mat-card-subtitle class="d-flex justify-content-center"> Dashboard</mat-card-subtitle>
      <mat-card-title class="mb-4"> {{ dash.name }} </mat-card-title>

      <mat-card-content>
        <p><b>Llave::</b> {{ (dash.key.length > 15) ? (dash.key | slice : 0 : 15)+"..." : dash.key }}</p>
        <p><b>Id dashboard::</b> {{ (dash.report_id.length > 15) ? (dash.report_id | slice : 0 : 15)+"..." : dash.report_id }}</p>
      </mat-card-content>
      <mat-card-actions class="mb-1" style="width: 100%; text-align: center; position: absolute; bottom: 1em; ">
        <hr class="mt-4 mr-4">
        <button
        mat-raised-button
        (click)="editDash(dash)"
        color = "link">
        <mat-icon>edit_square</mat-icon>
        Editar
      </button>
      <button
      mat-raised-button
      color   = "link"
      (click) = "delete(dash)"
      >
      <mat-icon>delete</mat-icon>
      Eliminar
    </button>
  </mat-card-actions>

</mat-card>
</div>
</div>



  <div class="row" *ngIf="paginator">
    <mat-paginator
    #paginatorEl
    [length]          ="paginator.total"
    [pageSize]        ="paginator.per_page"
    [pageSizeOptions] ="[8,20,100]"
    aria-label        ="Seleccione una página"
    (page)            = "pageEvent = filterDash($event)"

    >
  </mat-paginator>
</div>

