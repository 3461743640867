import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CollaboratorInterface } from 'src/app/interfaces/CollaboratorInterface';
import { CustomerInterface } from 'src/app/interfaces/CustomerInterface';
import { PaginatorInterface } from 'src/app/interfaces/PaginatorInterface';
import { AlertService } from 'src/app/services/alert-service.service';
import { CustomerService } from 'src/app/services/customer.service';
import { EditUserComponent } from '../companies/edit-user/edit-user.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';




@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  findUserForm                              : FormGroup;
  paginator                                 : PaginatorInterface;
  collaborators                             : CollaboratorInterface[];
  customers                                 : CustomerInterface[];
  displayedColumns                          : string[]         = ['actions', 'name', 'email', 'id_number', 'customer' ];
  filteredCustomers                         : Observable<CustomerInterface[]>;
  @ViewChild('paginatorEl') paginatorEl     : MatPaginator;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;


  constructor(
    private customerService: CustomerService,
    private fb             : FormBuilder,
    public dialog          : MatDialog,
    private alertService   : AlertService
  ) { }

  ngOnInit(): void {
    this.findUserForm = this.fb.group({
      name        : [''],
      customer_id : ['']
    });
    this.getCollaborators();

    this.customerService.getAllCustomers().subscribe( (result:CustomerInterface[]) =>{
      this.customers = result;
      this.filteredCustomers = this.findUserForm.get("customer_id").valueChanges.pipe(
        startWith(''),
        map(value => this._filter_customer(value || '')),
      );
    } );
  }

  /**
   * Get collaborator from de back
   */
  getCollaborators(){
    const filter = this.findUserForm.value;
    //console.log( typeof(filter.customer_id) );
    if( typeof(filter.customer_id) == "object" && filter.customer_id && 'id' in filter.customer_id ){
      filter.customer_id = filter.customer_id.id;
    }

    this.customerService.getCollaborators( filter , this.paginatorEl ).subscribe( (result:PaginatorInterface) =>{
      this.collaborators = result.data;
      this.paginator     = result;
    } );
  }

  /**
   * Filter collaborators
   */
  filterUser(){
    this.paginatorEl.pageIndex = 0;
    this.getCollaborators();
  }

  /**
   * Filter customer from autocomplete
   * @param value customer name
   * @returns
   */
  private _filter_customer(value: string | CustomerInterface): CustomerInterface[] {
    if( typeof value !=="string" ){
      return;
    }
    const filterValue = value.toLowerCase();
    return this.customers.filter(cus => cus.name.toLowerCase().includes(filterValue));
  }

  /**
   * Check if customer exist in option list
   */
  ngAfterViewInit() {
    this.trigger.panelClosingActions.subscribe(e => {
      if (!(e && e.source)) {
        this.findUserForm.get("customer_id").setValue('');
        this.trigger.closePanel();
      }
    });
  }

  /**
   * Return de customer
   * @param customer
   * @returns
   */
  getCustomerName( customer:CustomerInterface ){
    return customer.name;
  }


  /**
   * Open dialog for create new user
   */
  createUser(){
    const dialgo = this.dialog.open( EditUserComponent );
    dialgo.afterClosed().subscribe( dialog=>{
      this.getCollaborators();
    } );
  }

  /**
   * Open dialog for edit user
   * @param collaborator
   */
  editUser( collaborator: CollaboratorInterface ){
    const dialgo = this.dialog.open( EditUserComponent ,
      {
        data:{
          collaborator: collaborator
        }
      }
    );
    dialgo.afterClosed().subscribe(dialog =>{
      this.getCollaborators();
    })
  }


  modalResetPassword( collaborator: CollaboratorInterface ){
    const dialogo = this.dialog.open( ResetPasswordComponent ,
      {
        data:{
          collaborator : collaborator
        }
      });
  }





}
