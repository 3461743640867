import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomerInterface } from '../interfaces/CustomerInterface';
import { catchError, map } from 'rxjs/operators';
import { PaginatorInterface } from '../interfaces/PaginatorInterface';
import { MatPaginator } from '@angular/material/paginator';
import { CollaboratorInterface } from '../interfaces/CollaboratorInterface';
import { DashboardInterface } from '../interfaces/DashboardInterface';
import { ConfigDashboardInterface } from '../interfaces/ConfigDashboardInterface';





@Injectable({
  providedIn: 'root'
})
export class SoulBIService {

  private url = environment.SOULBI_URL;
  private urlCustomer = environment.CUSTOMER_BACK_URL;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Almacena un nuevo dashboard
   * @param params parametros a almacenar
   * @returns
   */
  public saveDash( params ):Observable<DashboardInterface> {
    return this.http.post( `${this.url}external/reports` , params )
      .pipe( map( (dash:DashboardInterface) => dash ));
  }

  public editDash( params , id ):Observable<any> {
    return this.http.put( `${this.url}external/reports/${id}` , params )
      .pipe( map( (dash:DashboardInterface) => dash ));

  }

  /**
   * consulta todos los dashboards
   * @returns
   */
  public getDash(filter:any = {} , pagine):Observable<PaginatorInterface>{
    let params = new HttpParams();

    // Filtro por cliente
    if( filter.key != null && filter.key !== "" ){
      params = params.append('key', filter.key );
    }
    if( filter.name != null && filter.name !== "" ){
      params = params.append('name', filter.name );
    }

    if( pagine && Number.isInteger(pagine.pageIndex) ){
      params = params.append('page', (pagine.pageIndex + 1).toString() );
    }
    return this.http.get(`${this.url}external/reports` , {params:params})
      .pipe(map( (dash:PaginatorInterface) => dash ));
  }

  /**
   * Consulta todos los dashboards disponibles
   * @returns
   */
  public getAllDashes() :Observable<DashboardInterface[]> {
    return this.http.get( `${this.url}external/reports/get_all` )
      .pipe(map( (dashes:DashboardInterface[]) => dashes ))

  }

  /**
   * Consulta los dashboards asignados al usuario loguardo
   * @returns
   */
  public getUserReports( userId:number, customerId:number ):Observable<DashboardInterface[]> {
    return this.http.get(`${this.url}external/get_user_reports/${userId}/${customerId}`)
      .pipe( map ((dashes:DashboardInterface[]) => dashes ) )
  }

  /**
   * Envía petición para eliminar un dashboard
   * @returns
   */
  public deleteDash(dashboardId): Observable<any> {
    return this.http.delete(`${this.url}external/reports/${dashboardId}`);

  }


  /**
   * Consulta las asignaciones
   * @param filter filtros de nombre de usuario  y cliente
   * @param pagine filtros por página
   * @returns
   */
  async  getAssig(filter:any = {} , pagine) : Promise<PaginatorInterface> {
    let params = new HttpParams();

    // Filtro por id
    if( filter.id != null && filter.id !== "" ){
      params = params.append('id', filter.id );
    }

    // Filtro por dashboard
    if( filter.name != null && filter.name !== "" ){
      params = params.append('name', filter.name );
    }

    // Filtro por cliente
    if( filter.customer_id != null && filter.customer_id !== "" ){
      params = params.append('customer_id', filter.customer_id );
    }

    //Filtro por usario
    if( filter.collab_id != null && filter.collab_id !== "" ){
      params = params.append('user_id', filter.collab_id );
    }


    if( pagine && Number.isInteger(pagine.pageSize) ){
      params = params.append('size', (pagine.pageSize).toString() );
    }

    if( pagine && Number.isInteger(pagine.pageIndex) ){
      params = params.append('page', (pagine.pageIndex + 1).toString() );
    }


    const customers:CustomerInterface[]         = await this.http.get(`${this.urlCustomer}customer/get_all`).toPromise().then( (res:CustomerInterface[]) => res) ;
    const collaborators:CollaboratorInterface[] = await this.http.get(`${this.urlCustomer}collaborator/get_all`).toPromise().then( (res:CollaboratorInterface[]) => res) ;
    const assign:PaginatorInterface             = await this.http.get(`${this.url}external/reports_user` , {params:params}).toPromise().then( (res:PaginatorInterface) => res ) ;

    assign.data.map( ( config:ConfigDashboardInterface ) => {
      config.customers.map( (cus:CustomerInterface ,idx )=>{
        const tmpCus = customers.find( c => c.id == cus.customer_id );
        if( tmpCus !== undefined) {
          config.customers[idx] = tmpCus;
        }
        else
        {
          config.customers = config.customers.splice(idx , 1);
        }
      });

      config.users.map( (user:CollaboratorInterface ,idx )=>{
        const tmpConfig = collaborators.find( c => c.id == user.user_id )
        if( tmpConfig !== undefined ){
          config.users[idx] = tmpConfig;
        }
        else
        {
          config.users = config.users.splice(idx , 1);
        }
      });
    } );

    return assign;
  }

  /**
   * elimina una configuración
   * @param assignId
   * @returns
   */
  public deleteAssign( assignId:number ):Observable<any> {
    return this.http.delete(`${this.url}external/reports_user/${assignId}`);

  }

  /**
   * Cera una nueva configuración
   * @param data
   * @returns
   */
  public saveAssign( data ) : Observable<any> {
    return this.http.post(`${this.url}external/reports_user` , data ) ;
  }


  /**
   * Actualiza una configuración
   * @param data
   * @param id
   * @returns
   */
  public updateAssign( data , id ) : Observable<any> {
    return this.http.put(`${this.url}external/reports_user/${id}` , data ) ;
  }


  /**
   * Consulta el token de un por la llave
   * @param reportKey  llave o key a consultar
   * @param collabId  id del colaborador que consulta
   * @returns
   */
  public getToken( reportKey:string , collabId:number ) : Observable<any> {
    return this.http.get(`${this.url}external/get_token/${reportKey}/${collabId}` ) ;
  }



}
