<div class="row ml-3 mr-5">
  <div class="col-md-6">
    <p class="d-flex justify-content-center">
      <img src="assets/images/g10.svg" alt="">
    </p>
    <p class="d-flex justify-content-center mt-5">
      <img class="img-cambio" src="assets/images/cambio.svg" alt="">
    </p>
  </div>
  <div class="col-md-6 login-box">
    <h1 class="d-flex justify-content-center welcome-txt">¡Bienvenido!</h1>
    <div class="d-flex justify-content-center">Módulo de consulta de informes</div>

    <form [formGroup]="loginForm" class="login-form m-5">
      <p>
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Nombre de usuario *</mat-label>
          <input
          matInput
          formControlName = "username"
          type            ="tel"
          autocomplete    ="off"
          placeholder     ="Nombre de usuario"
          >
          <mat-error>Campo obligatorio</mat-error>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Contraseña *</mat-label>
          <input
          matInput
          [type]          = "hide ? 'password' : 'text'"
          formControlName = "password"
          autocomplete    ="off"
          placeholder     ="Password"
          >
          <mat-icon matSuffix (click)="view_password()">
            {{ hide ? "visibility_on" : "visibility_off"}}
          </mat-icon>
          <mat-error>Campo obligatorio</mat-error>
        </mat-form-field>
      </p>

      <p>
        <button
          mat-raised-button
          [disabled] ="!loginForm.valid"
          [color]    = "loginForm.valid ? 'success' : 'disabled' "
          class      = "w-100"
          (click)    ="login()"
          >
          Ingresar</button>
        </p>
      </form>


    </div>
  </div>

