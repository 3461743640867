import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CollaboratorInterface } from 'src/app/interfaces/CollaboratorInterface';
import { AlertService } from 'src/app/services/alert-service.service';
import { UserServiceService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  formPassword: FormGroup;
  collaborator: CollaboratorInterface;
  showPassword: boolean = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public fb            : FormBuilder,
    private userService  : UserServiceService,
    private alertService : AlertService,
    private dialogRef    : MatDialogRef<ResetPasswordComponent>
  ) { }

  ngOnInit(): void {
    this.collaborator = this.data["collaborator"];
    this.formPassword = this.fb.group({
      password: ["" , [Validators.required , Validators.minLength(6)] ]
    });
  }

  resetPassword(){
    this.userService.getResetToken( this.collaborator.id_number ).subscribe( token => {
      this.userService.resetPassword(token["token"] , this.formPassword.get("password").value ).subscribe( result => {
        this.alertService.success("¡Correcto!","Contraseña reestablecida correctamente");
        this.dialogRef.close();
      } );
    });
  }

}
