<button class="close" mat-button mat-dialog-close>
  <img src="assets/images/close-modal.svg" alt="">
</button>

<div mat-dialog-content class="ml-5 mr-5">
  <h2 class="mt-3 mb-1"> {{ data ? "Editar" : "Crear" }} usuario</h2>
  <hr class="mt-0">
  <form class="mt-4" [formGroup]="formUser" >
    <div class="row">
      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Nombre*</mat-label>
          <input
            matInput
            autocomplete    ="off"
            formControlName ="first_name"
            placeholder     ="Nombres completos"
            >
            <mat-error>Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Apellido*</mat-label>
          <input
            matInput
            autocomplete    ="off"
            formControlName ="last_name"
            placeholder     ="Apellidos completos"
            >
            <mat-error>Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Email</mat-label>
          <input
            matInput
            formControlName = "email"
            autocomplete    ="off"
            type            ="email"
            placeholder     ="Correo electrónico"
            >
            <mat-error>Seleccione un email válido</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Número de identificación*</mat-label>
          <input
            matInput
            formControlName ="id_number"
            autocomplete    ="off"
            placeholder     ="Número de identificación"
            >
            <mat-error *ngIf="formUser.controls['id_number'].errors?.required">Campo obligatorio</mat-error>
            <mat-error *ngIf="formUser.controls['id_number'].errors?.pattern" >Únicamente valores numéricos</mat-error>

        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Passsword*</mat-label>
          <input
            matInput
            [type]          = "showPasssword ? 'text' : 'password'"
            formControlName = "password"
            autocomplete    = "off"
            placeholder     = "Sólo numeros"
            >
            <mat-icon
              matSuffix
              *ngIf="showPasssword"
              (click)="showPasssword = !showPasssword"
                >visibility</mat-icon>

            <mat-icon
              matSuffix
              *ngIf="!showPasssword && !data"
              (click)="showPasssword = !showPasssword"
                >visibility_off</mat-icon>

            <mat-error
              *ngIf="formUser.controls['password'].errors?.required" >
              Campo obligatorio</mat-error>

              <mat-error
              *ngIf="formUser.controls['password'].errors?.minlength" >
              Mínimo 6 caracteres requeridos</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Cliente</mat-label>
          <input
            matInput
            formControlName ="customer_id"
            type            ="text"
            placeholder     ="Seleccione un cliente"
            [matAutocomplete]="auto">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getCustomerName.bind(this)">
            <mat-option *ngFor="let customer of filteredCostumers | async" [value]="customer.id">
              {{customer.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error>Seleccione un elemento de la lista</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="data">
      <div class="col-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Estado</mat-label>
          <mat-select formControlName="state">
            <mat-option [value]="1">Activo</mat-option>
            <mat-option [value]="0">Inactivo</mat-option>
          </mat-select>
          <mat-error>Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="d-flex justify-content-center mt-5 mb-3">
    <button
          mat-raised-button
          mat-dialog-close
          color    = "basic"
          class    = "mr-1 dialog-button"
          >
          Cancelar</button>
    <button
          mat-raised-button
          [color]    = "formUser.valid ? 'success' : 'basic'"
          [disabled] = "formUser.invalid"
          (click)    = "saveUser()"
          class      = "ml-1 dialog-button"
          >
          Guardar</button>
  </div>
</div>

