<h1>Administración de empresas</h1>
<p class="module-help-text">
  En este espacio podrás revisar y/o crear empresas. <br>
  No olvides, “El respeto y empatía son clave”.
</p>


<div class="row mt-5">
  <div class="col-md-7 col-sm-12 findCustomerForm">
    <form [formGroup]="findCustomerForm" class="">
      <mat-form-field appearance="fill">
        <mat-label>Razón social</mat-label>
        <input
          matInput
          type            ="text"
          formControlName ="name"
          placeholder     ="Razón social"
          autocomplete    ="off"
          (keyup.enter)   = "filterCustomer()"
          (blur)          = "filterCustomer()"
          >

        <mat-icon
          matSuffix
          style             ="cursor: pointer;"
          (click)           ="filterCustomer()"
            >search</mat-icon>
      </mat-form-field>
        <mat-form-field appearance="fill" >
          <mat-label>Nit</mat-label>
          <input
            matInput
            autocomplete    ="off"
            (keyup.enter)   = "filterCustomer()"
            (blur)          = "filterCustomer()"
            formControlName ="nit"
            placeholder     ="Nit">
          <mat-icon
            matSuffix
            style           ="cursor: pointer;"
            (click)         ="filterCustomer()"
              >search</mat-icon>
        </mat-form-field>
    </form>
  </div>

  <div class="col-md-5 col-sm-12" style="text-align: right;" >
    <button
      mat-raised-button
      (click)="createCompany()"
      color = "success">
        Crear empresa
      <mat-icon>add_circle</mat-icon>
    </button>
  </div>
</div>



<div class="row mb-5">
  <div class="col-md-3 mt-5 mb-5" *ngFor="let customer of customers , let idx = index ">
    <div class="card-icon d-flex justify-content-center">
      <mat-icon>domain</mat-icon>
    </div>
    <mat-card style="height: 130%;">
        <mat-card-subtitle class="d-flex justify-content-center"> Empresa</mat-card-subtitle>
        <mat-card-title class="mb-4"> {{ customer.name }} </mat-card-title>

      <mat-card-content>
        <p><b>Nit:</b> {{ customer.nit }}</p>
        <p><b>Fecha de creación:</b> {{ customer.created_at |  date: 'dd/MM/yyyy' }}</p>
      </mat-card-content>
      <mat-card-actions class="mb-1" style="width: 100%; text-align: center; position: absolute; bottom: 1em; ">
        <hr class="mt-4 mr-4">
        <button
            mat-raised-button
            (click)="editCompany(customer)"
            color = "link">
            <mat-icon>edit_square</mat-icon>
              Editar
          </button>
          <button
            mat-raised-button
            color   = "link"
            (click) = "delete(customer)"
            >
            <mat-icon>delete</mat-icon>
              Eliminar
          </button>
      </mat-card-actions>

    </mat-card>
  </div>
</div>

<div class="row" *ngIf="paginator">
  <mat-paginator
    #paginatorEl
    [length]          ="paginator.total"
    [pageSize]        ="paginator.per_page"
    [pageSizeOptions] ="[8,20,100]"
    aria-label        ="Seleccione una página"
    (page)            = "pageEvent = getCustomer($event)"

      >
  </mat-paginator>
</div>
