<button class="close" mat-button mat-dialog-close>
  <img src="assets/images/close-modal.svg" alt="">
</button>

<div mat-dialog-content class="ml-5 mr-5">
  <h2 class="mt-3 mb-1"> {{ data ? "Editar" : "Crear" }} dashboard</h2>
  <hr class="mt-0">
  <form class="mt-4" [formGroup]="formDash" >
    <div class="row">
      <div class="col-md-4">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Nombre*</mat-label>
          <input
            matInput
            autocomplete    ="off"
            formControlName ="name"
            placeholder     ="Nombre del dashboard"
            >
            <mat-error>Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>llave*</mat-label>
          <input
            matInput
            autocomplete    ="off"
            formControlName ="key"
            placeholder     ="Key del dashboard"
            >
            <mat-error>Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Id del dashboard*</mat-label>
          <input
            matInput
            formControlName = "report_id"
            autocomplete    ="off"
            placeholder     ="Id del dashboard"
            >
            <mat-error>Seleccione un email válido</mat-error>
        </mat-form-field>
      </div>
    </div>

  </form>

  <div class="d-flex justify-content-center mt-5 mb-3">
    <button
          mat-raised-button
          mat-dialog-close
          color    = "basic"
          class    = "mr-1 dialog-button"
          >
          Cancelar</button>
    <button
          mat-raised-button
          [color]    = "formDash.valid ? 'success' : 'basic'"
          [disabled] = "formDash.invalid"
          (click)    = "saveDash()"
          class      = "ml-1 dialog-button"
          >
          Guardar</button>
  </div>
</div>

